<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			资讯管理
			<span>&gt;</span>
			新闻资讯
			<span>&gt;</span>
			增加新闻
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/news/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-form :model="article" status-icon :rules="rules" ref="elForm" label-width="100px" class="article-form">
				<el-form-item label="标题" prop="title">
					<el-input v-model="article.title" placeholder="请输入新闻标题"></el-input>
				</el-form-item>
				<el-form-item label="正文" prop="text">
					<div id="wangEditor"></div>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" :rows="2" placeholder="备注" v-model="article.note"> </el-input>
				</el-form-item>
				<el-form-item label="置顶" prop="top">
					<el-switch v-model="is_top" @change="changeTop"></el-switch>
					<el-input
						ref="inputTop"
						style="width:170px;margin-left:10px;display: inline-block;"
						v-model.number="article.top"
						placeholder="请输入权重"
					></el-input>
				</el-form-item>
				<el-form-item label="设置为焦点" prop="focus">
					<el-switch v-model="article.is_focus" style="vertical-align: top;margin-top: 12px;" @change="changeFocus"></el-switch>
					<el-upload
						class="upload-demo"
						style="margin-left:10px;"
						:action="apiServer + '/api/v1/admin/article/add'"
						:auto-upload="false"
						:limit="1"
						:on-remove="handleRemove"
						:on-exceed="handleExceed"
						:on-change="handleChange"
						:on-success="onSuccess"
						:file-list="fileList"
						:data="article"
						:headers="header"
						name="image"
						ref="uploadImage"
						list-type="picture"
					>
						<el-button size="small" type="primary">选择本地图片</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="发布时间" prop="release_time">
					<el-date-picker
						v-model="article.release_time"
						value-format="yyyy-MM-dd HH:mm:ss"
						type="datetime"
						placeholder="发布时间"
						align="right"
						:picker-options="pickerOptions"
					>
					</el-date-picker>
				</el-form-item>

				<el-form-item style="margin-top:20px;">
					<el-button type="primary" round @click="submitForm(1)">发布文章</el-button>
					<el-button type="info" round @click="submitForm(0)">保存草稿</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
import { apiAddArticle } from "@/api/admin/article.js";
import base from "@/base.js";

export default {
	data() {
		var checkEmpty = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("不能为空"));
			} else {
				callback();
			}
		};
		return {
			apiServer: base.apiServer,
			header: { token: this.$store.state.token },
			category_label: "新闻资讯",
			is_top: false,
			article: {
				title: "",
				text: "",
				note: "",
				top: 0,
				status: "",
				is_focus: false,
				focus_image_url: "",
				release_time: this.formatDate(new Date())
			},
			rules: {
				title: [{ validator: checkEmpty, trigger: "blur" }]
			},
			fileList: [],
			editor: null,
			pickerOptions: {
				shortcuts: [
					{
						text: "今天",
						onClick(picker) {
							picker.$emit("pick", new Date());
						}
					},
					{
						text: "昨天",
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit("pick", date);
						}
					},
					{
						text: "一周前",
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit("pick", date);
						}
					}
				]
			}
		};
	},
	methods: {
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},
		handleExceed() {
			this.$message.error("只能上传一张照片，请先删除之前选择的照片");
		},
		handleChange(file, fileList) {
			//console.log("handleChange fileList>>", fileList);
			this.fileList = fileList;
		},
		changeTop() {
			if (this.is_top === true) {
				this.article.top = "";
				this.$refs.inputTop.$el.style.display = "inline-block";
			} else {
				this.article.top = 0;
				this.$refs.inputTop.$el.style.display = "none";
			}
		},
		changeFocus() {
			if (this.article.is_focus === true) {
				this.$refs.uploadImage.$el.style.display = "inline-block";
			} else {
				this.$refs.uploadImage.$el.style.display = "none";
			}
		},
		// 图片上传成功
		onSuccess: function(response) {
			console.log(response);
			if (response.code === 200) {
				this.$message({ message: "成功保存", type: "success" });
				//执行成功1秒后跳转页面
				setTimeout(function() {
					window.location.href = "/pc/admin/news/list";
				}, 1000);
			} else {
				this.$message.error(response.message);
			}
		},
		submitForm(status) {
			this.$refs.elForm.validate((valid) => {
				if (valid) {
					this.article.status = status;
					this.article.category_label = this.category_label;
					//console.log(this.article);
					if (this.article.top === "") {
						this.$message.error("权重不能为空");
						return;
					}
					if (this.article.text == "") {
						this.$message.error("正文不能为空");
						return;
					}
					//console.log(this.article);
					if (this.article.is_focus) {
						//需要保存焦点图片
						//console.log(this.fileList);
						if (this.fileList.length === 0) {
							this.$message.error("未选择焦点图片");
							return;
						}
						this.$refs.uploadImage.submit();
					} else {
						//不需要保存焦点图片
						var formData = new FormData();
						formData.append("title", this.article.title);
						formData.append("text", this.article.text);
						formData.append("note", this.article.note);
						formData.append("category_label", this.article.category_label);
						formData.append("top", this.article.top);
						formData.append("status", this.article.status);
						formData.append("is_focus", this.article.is_focus);
						formData.append("focus_image_url", this.article.focus_image_url);
						formData.append("release_time", this.article.release_time);
						/* for (var [a, b] of formData.entries()) {
							console.log(a + ":", b || '""');
						} */
						//保存文章
						apiAddArticle(this.$store.state.token, formData).then((response) => {
							if (response.data.code === 200) {
								this.$message({ message: "成功保存", type: "success" });
								//执行成功1秒后跳转页面
								setTimeout(function() {
									window.location.href = "/pc/admin/news/list";
								}, 1000);
							} else if (response.data.code == -100) {
								this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "error"
								})
									.then(() => {
										//跳转到登录页面（路由跳转则滑动验证会失效）
										//this.$router.push({ path: "/login?target=" + window.location.href });
										window.location.href = "/login?target=" + window.location.href;
									})
									.catch(() => {
										this.$message({
											type: "warning",
											message: "已取消"
										});
									});
							} else {
								console.log("response>>", response);
								this.$message.error(response.data.message);
							}
						});
					}
				} else {
					if (this.article.title == "") {
						this.$message.error("标题不能为空");
					}
				}
			});
		},

		formatDate(date) {
			return (
				date.getFullYear() +
				"-" +
				this.fillZero(date.getMonth() + 1) +
				"-" +
				this.fillZero(date.getDate()) +
				" " +
				this.fillZero(date.getHours()) +
				":" +
				this.fillZero(date.getMinutes()) +
				":" +
				this.fillZero(date.getSeconds())
			);
		},
		fillZero(num) {
			return num < 10 ? "0" + num : num;
		}
	},
	mounted() {
		//生成编辑器
		const editor = new wangEditor("#wangEditor");
		//上传文件的名字
		editor.config.uploadFileName = "image";
		// 配置 server 接口地址
		editor.config.uploadImgServer = this.apiServer + "/api/v1/admin/article/upload_image";
		// 1M
		editor.config.uploadImgMaxSize = 1024 * 1024;
		//限制上传格式
		editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif", "bmp"];
		// 一次最多上传 5 个图片
		editor.config.uploadImgMaxLength = 5;
		//设置token
		editor.config.uploadImgHeaders = { token: this.$store.state.token };
		//设置参数
		editor.config.uploadImgParams = { type: "news" };
		//设置z-index
		editor.config.zIndex = 500;
		//默认编辑器占高300px,设置为320
		editor.config.height = 320;

		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			this.article.text = newHtml;
		};
		// 创建编辑器
		editor.create();
		this.editor = editor;

		this.changeTop();
		this.changeFocus();
	},
	beforeDestroy() {
		// 调用销毁 API 对当前编辑器实例进行销毁
		this.editor.destroy();
		this.editor = null;
	}
};
</script>

<style lang="scss" scoped>
.article-form {
	margin: 10px 20px;
}
</style>
